<template>
  <about
    title="Healthcare That Counts"
    subtitle=""
    img="hero/doctor.jpg"
    title2="About us"
  >
    <p>
      Matungulu Medical Health and Wellness Centre is an NHIF accredited 17-bed
      private medical facility situated in the heart of Tala Market. We are a
      level three medical facility well placed to serve both Matungulu and
      Kangundo Sub-Counties. Since our establishment in 2009, we have developed
      a rich history of serving a unique and vital role in improving the health
      of our community in these two sub-counties. We offer round-the-clock
      outpatient, laboratory, pharmacy, inpatient and maternity services.
    </p>
  </about>
</template>
<script>
import about from "@/components/sections/about.vue";
export default {
  components: { about },
  mounted: function() {
    this.$vuetify.goTo(0);
  },
};
</script>
